import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import IPVCards01 from 'components/Software/iOS/IP_Vision_Pro/ipvCard01';
import IPVCards02 from 'components/Software/iOS/IP_Vision_Pro/ipvCard02';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "iOS Software for your INSTAR IP Camera",
  "path": "/Software/iOS/IP_Vision_Pro/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The iOS app IP VisionPro for your iPad or iPhone. Control your camera when you don´t have access to your computer.",
  "image": "./P_SearchThumb_IP_VisionPro.png",
  "social": "/images/Search/P_SearchThumb_IP_VisionPro.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS_IP-Vision-Pro_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='iOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The iOS app IP VisionPro for your iPad or iPhone. Control your camera when you don´t have access to your computer.' image='/images/Search/P_SearchThumb_IP_VisionPro.png' twitter='/images/Search/P_SearchThumb_IP_VisionPro.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/iOS/IP_Vision_Pro/' locationFR='/fr/Software/iOS/IP_Vision_Pro/' crumbLabel="IP Vision Pro" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ios",
        "aria-label": "ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOS`}</h2>
    <h3 {...{
      "id": "ip-vision-pro",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ip-vision-pro",
        "aria-label": "ip vision pro permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Vision Pro`}</h3>
    <p>{`To access your IP camera with the iOS App IP Vision Pro we recommend that you first download the newest version from the app store. Use the search words IP Vision Pro to find the app inside the app store.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.ttrix.com/apple/iphone/ipvisionpro/ipvisionpro.html"
        }}>{`IP Vision Pro - Website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://itunes.apple.com/us/app/ip-vision-pro/id306446764?mt=8"
        }}>{`IP Vision Pro - iTunes`}</a></li>
    </ul>
    <p>{`To access your IP camera with the iOS App LiveCams we recommend that you first download the newest version from the app store. Use the search word LiveCams to find the app inside the app store.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://itunes.apple.com/us/app/live-cams-pro-foscam-multi/id428145132?mt=8"
        }}>{`LiveCams`}</a></li>
    </ul>
    <h3 {...{
      "id": "add-a-camera-to-ip-vision-pro",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera-to-ip-vision-pro",
        "aria-label": "add a camera to ip vision pro permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera to IP Vision Pro`}</h3>
    <p>{`Launch IP Vision Pro on your iPhone or iPad and press the camera button to add your IP camera. Press Edit to add your camera to the default camera group choose Add Camera to continue. If your camera is a VGA model, you can choose the corresponding preset. For HD cameras, please choose INSTAR/generic or INSTAR/generic PTZ (for cameras with pan, tilt and zoom functions). You can find the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/VGA_Series_CGI_List/"
      }}>{`MJPEG CGI Command in our CGI Documentation`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/cgi-bin/hi3510/mjpegstream.cgi?-chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <ul>
      <li parentName="ul">{`chn: Stands for Channel and can have the values 11 (1280x720), 12 (640x352) and 13 (320x176). If you are experiencing dropped frames or have difficulties reaching your camera when you are on the road, please consider to use a smaller video stream.`}</li>
      <li parentName="ul">{`usr: Stands for your username that you set up inside the camera. The default value is admin`}</li>
      <li parentName="ul">{`pwd: Stands for your password that you set up inside the camera. The default value is instar`}</li>
    </ul>
    <IPVCards01 mdxType="IPVCards01" />
    <h3 {...{
      "id": "view-your-cameras-live-video-and-start-local-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#view-your-cameras-live-video-and-start-local-recordings",
        "aria-label": "view your cameras live video and start local recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`View your cameras live video and start local recordings`}</h3>
    <p>{`You will be greeted by your camera live video every time you open IP Vision Pro. Just click on the video area to access advanced functions like video and snapshot recordings.`}</p>
    <IPVCards02 mdxType="IPVCards02" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      